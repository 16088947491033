import styled from "styled-components";
import { breakpoints } from "../styles/variables";

export const LandingPageWrapper = styled.div`
    background-color: #21161630;
    // @media (min-width: ${breakpoints.xl}) {
    //     background-color: #21161630;
    // }
`;

const PageContainer = styled.div`
    max-width: ${breakpoints.xl};
    margin: 2rem auto 0 auto;
    padding: 0 1rem;

    @media (min-width: ${breakpoints.xl}) {
        padding: 0;
        margin: 0 auto;
    }
`;

export default PageContainer;
