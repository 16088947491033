import styled from "styled-components";
import { FaMapMarkerAlt } from "react-icons/fa";
import { breakpoints, colors } from "../../styles/variables";
import Flex from "../Flex";

export const ContactWrapper = styled(Flex)`
    margin: 0 auto;
    padding: 2rem 0;
    flex-wrap: wrap;
    min-height: calc(100vh - 47.15rem);
`;

export const InfoContainer = styled.div`
    width: 100%;
    margin: 2rem auto;
    @media (min-width: ${breakpoints.md}) {
        width: 17.25rem;
        flex: 1;
        margin: auto auto auto 1.5rem;
    }
    @media (min-width: ${breakpoints.lg}) {
        margin: auto auto auto 3rem;
    }
`;
export const InfoBox = styled.div`
    text-align: left;
    margin: 1rem auto 2rem;
    padding-left: 0.5rem;
    @media (min-width: ${breakpoints.md}) {
        margin: 0.5rem auto 1rem;
    }
    @media (min-width: ${breakpoints.lg}) {
        margin: 1rem auto 2rem;
    }
`;
export const InfoTitle = styled.h2`
    font-size: 1.125rem;
    color: ${colors.dark};
    text-align: left;
    font-weight: 500;
    margin: 0 0 0.5rem 0;
    @media (min-width: ${breakpoints.sm}) {
        margin: 0 0 0.5rem 1rem;
        font-size: 1.125rem;
    }
`;
export const InfoText = styled.span`
    font-weight: 300;
    font-size: 1rem;
    @media (min-width: ${breakpoints.sm}) {
        margin-left: 1rem;
        font-size: 1.125rem;
    }
`;
export const StyledAnchor = styled.a`
    font-size: 1rem;
    color: ${colors.dark};
    text-decoration: none;
    &:hover {
        color: ${colors.primary};
    }
`;
export const MapContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    position: relative;
    @media (min-width: ${breakpoints.md}) {
        flex: 1;
    }
`;
export const AddressBox = styled.div`
    @media (min-width: ${breakpoints.sm}) {
        margin-left: 1rem;
    }
`;
export const AddressText = styled.span`
    display: block;
    font-weight: 300;
    font-size: 1rem;
    @media (min-width: ${breakpoints.sm}) {
        display: inline;
        font-size: 1.125rem;
    }
`;
export const MapPoint = styled(FaMapMarkerAlt)`
    position: absolute;
    z-index: 2;
    top: 31%;
    right: 45%;
    color: red;
    font-size: 1.625rem;
    @media (min-width: ${breakpoints.sm}) {
        top: 30%;
        right: 42%;
    }
`;
export const SmSizeImgBox = styled.div`
    @media (min-width: ${breakpoints.sm}) {
        display: none;
    }
`;
export const OtherSizeImgBox = styled.div`
    display: none;
    @media (min-width: ${breakpoints.sm}) {
        display: block;
    }
`;
