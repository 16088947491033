import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";

const StyledLi = styled.li`
    display: inline;
    &:first-child {
        &::before {
            content: none;
            padding-left: 0;
        }
    }
    &::before {
        content: "〉";
        padding: 0.5rem;
        font-size: 1.125rem;
        font-weight: 700;
        color: ${colors.darkGray};
    }
`;
const BreadcrumbLink = styled(Link)`
    font-size: 1rem;
    font-weight: 400;
    color: ${colors.darkGray};
    text-decoration: none;
    @media (min-width: ${breakpoints.md}) {
        font-size: 1.25rem;
    }
    &:hover {
        color: ${colors.primary};
        text-decoration: underline;
    }
`;
const BreadcrumbContainer = styled.ol`
    margin: 2rem 1rem 2rem 0;
    padding: 0;
    list-style: none;
    font-size: 1rem;
    @media (min-width: ${breakpoints.md}) {
        margin: 2rem 1rem 2rem 0;
        font-size: 1.25rem;
    }
`;

export const Breadcrumb = ({ children }) => (
    <BreadcrumbContainer>
        <BreadcrumbItem Link="/" Text="Home" />
        {children}
    </BreadcrumbContainer>
);

export const BreadcrumbItem = ({
    Link,
    Text,
}: {
    Link: string;
    Text: string;
}) => (
    <StyledLi>
        <BreadcrumbLink to={Link}>{Text}</BreadcrumbLink>
    </StyledLi>
);
