import styled from "styled-components";
import { colors, breakpoints } from "../styles/variables";

const PageTitle = styled.h1`
    font-size: 2rem;
    margin-bottom: 3rem;
    color: ${colors.darkGray};

    @media (min-width: ${breakpoints.sm}) {
        font-size: 4rem;
    }
`;

export default PageTitle;
