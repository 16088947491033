import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Image = () => {
    const data = useStaticQuery(graphql`
        query {
            smallMapImg: file(relativePath: { eq: "koffia_mobile_map.png" }) {
                childImageSharp {
                    fluid(maxWidth: 600, grayscale: true) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
            otherMapImg: file(relativePath: { eq: "koffia_map.png" }) {
                childImageSharp {
                    fluid(maxWidth: 740, grayscale: true) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `);
    return {
        smallMap: data.smallMapImg.childImageSharp.fluid,
        otherMap: data.otherMapImg.childImageSharp.fluid,
    };
};
export const SmallMapImage = () => {
    const image = Image();
    return <Img fluid={image.smallMap} />;
};

export const OtherMapImage = () => {
    const image = Image();
    return <Img fluid={image.otherMap} />;
};
