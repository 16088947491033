import React from "react";
import {
    ContactWrapper,
    InfoContainer,
    InfoBox,
    InfoTitle,
    InfoText,
    AddressText,
    StyledAnchor,
    AddressBox,
    MapContainer,
    MapPoint,
    SmSizeImgBox,
    OtherSizeImgBox,
} from "./styles";
import { SmallMapImage, OtherMapImage } from "./Images";
import PageTitle from "../PageTitle";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";

const Contact = () => {
    return (
        <PageContainer>
            <Breadcrumb>
                <BreadcrumbItem Link="/contact" Text="Contact" />
            </Breadcrumb>
            <PageTitle>Contact</PageTitle>
            <ContactWrapper>
                <MapContainer>
                    <StyledAnchor
                        href="https://www.google.com/maps/place/Korean+Cultural+Centre/@-33.8757622,151.2072083,17z/data=!3m1!4b1!4m5!3m4!1s0x6b12ae3d9183e319:0x4a768edc321f45f7!8m2!3d-33.8757622!4d151.2094023"
                        target="_blank"
                    >
                        <MapPoint />
                    </StyledAnchor>
                    <SmSizeImgBox>
                        <SmallMapImage />
                    </SmSizeImgBox>
                    <OtherSizeImgBox>
                        <OtherMapImage />
                    </OtherSizeImgBox>
                </MapContainer>
                <InfoContainer>
                    <InfoBox>
                        <InfoTitle>Organiser</InfoTitle>
                        <StyledAnchor
                            href="http://www.koreanculture.org.au"
                            target="_blank"
                        >
                            <InfoText>
                                Korean Cultural Centre Australia
                            </InfoText>
                        </StyledAnchor>
                    </InfoBox>
                    <InfoBox>
                        <InfoTitle>Address</InfoTitle>
                        <StyledAnchor
                            href="https://www.google.com/maps/place/Korean+Cultural+Centre/@-33.8757622,151.2072083,17z/data=!3m1!4b1!4m5!3m4!1s0x6b12ae3d9183e319:0x4a768edc321f45f7!8m2!3d-33.8757622!4d151.2094023"
                            target="_blank"
                        >
                            <AddressBox>
                                <AddressText>
                                    Ground Floor, 255 Elizabeth St.
                                </AddressText>
                                <AddressText> Sydney NSW 2000</AddressText>
                            </AddressBox>
                        </StyledAnchor>
                    </InfoBox>
                    <InfoBox>
                        <InfoTitle>Email</InfoTitle>
                        <StyledAnchor href="mailto:info@koffia.com.au">
                            <InfoText>info@koffia.com.au</InfoText>
                        </StyledAnchor>
                    </InfoBox>
                </InfoContainer>
            </ContactWrapper>
        </PageContainer>
    );
};

export default Contact;
